// extracted by mini-css-extract-plugin
export var arrow = "tequila-single-module--arrow--00fbc";
export var backgroundImage = "tequila-single-module--backgroundImage--be4af";
export var bg = "tequila-single-module--bg--02e60";
export var bottle = "tequila-single-module--bottle--7623a";
export var bottom = "tequila-single-module--bottom--3ea1e";
export var close = "tequila-single-module--close--6272b";
export var content = "tequila-single-module--content--00531";
export var enjoy = "tequila-single-module--enjoy--c6621";
export var features = "tequila-single-module--features--ef997";
export var first = "tequila-single-module--first--d3c4a";
export var float = "tequila-single-module--float--b3ccb";
export var image = "tequila-single-module--image--3eee5";
export var item = "tequila-single-module--item--226cf";
export var items = "tequila-single-module--items--4ec62";
export var last = "tequila-single-module--last--2ebf2";
export var left = "tequila-single-module--left--30762";
export var leftText = "tequila-single-module--leftText--d2a68";
export var link = "tequila-single-module--link--5f5e8";
export var name = "tequila-single-module--name--a7caf";
export var notes = "tequila-single-module--notes--a1168";
export var number = "tequila-single-module--number--ee171";
export var ourEssence = "tequila-single-module--ourEssence--c0a1a";
export var popUp = "tequila-single-module--popUp--de2a5";
export var process = "tequila-single-module--process--4d3f7";
export var processImg = "tequila-single-module--processImg--366a0";
export var processLogo = "tequila-single-module--processLogo--8ac0a";
export var right = "tequila-single-module--right--c06e1";
export var rightText = "tequila-single-module--rightText--a3784";
export var second = "tequila-single-module--second--bb3ac";
export var sello_icon = "tequila-single-module--sello_icon--315f5";
export var singleCover = "tequila-single-module--singleCover--1c9ca";
export var singleDescription = "tequila-single-module--singleDescription--07e80";
export var subtitle = "tequila-single-module--subtitle--316f1";
export var top = "tequila-single-module--top--c8f46";
export var year = "tequila-single-module--year--03d12";